import styled from '@emotion/styled';
import { Badge, theme } from '@smartproxy-web/ui';
import Cta from 'components/Cta';
import { DOM_STATE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { flexColContainerStyles } from 'commonStyles';

export const PricingCardsWrapper = styled.div<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor,
  alignItems: 'center',
  width: '100%',
  padding: `${theme.spacings.sectionSpacing} 20px`,
}));

export const PricingCardsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacings.XS,
  width: '100%',
  maxWidth: '1024px',
  zIndex: 1,
  marginBottom: '32px',
  flexWrap: 'wrap',
});

export const Title = styled.div({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.h2,
  fontWeight: 600,
  textAlign: 'center',
  marginBottom: '24px',
  color: theme.colors.black.S900,
  width: '100%',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    textAlign: 'left',
  },
});

export const StyledPricingCardFlag = styled(Badge)({
  position: 'absolute',
  top: '-10px',
});

export const StyledPricingCardContainer = styled.div<{ color?: string }>(
  ({ color }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
    // height: '242px',
    border: `1px solid ${color ?? theme.colors.neutral.N10}`,
    borderRadius: '12px',
    padding: '16px 16px 24px 16px',
    gap: '16px',

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '100%',
    },
  })
);

export const StyledPricingCardHeader = styled.div({
  textAlign: 'center',
  padding: '4px 0',
  borderRadius: '24px',
  backgroundColor: theme.colors.neutral.N04,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: theme.fonts.lineHeight.p_medium,
  '*': {
    fontFamily: theme.fonts.family.additional,
  },
});

export const StyledPricingCardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height: '100%',
  justifyContent: 'space-between',
});

export const StyledPricingCardDescription = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const StyledPricingCardPriceWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
});

export const StyledPricingCardPriceInnerWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'row',
  gap: '4px',
});

export const StyledPricingCardPrice = styled.div({
  fontFamily: theme.fonts.family.additional,
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: 700,
});

export const StyledPricingCardPerPrice = styled.div({
  fontFamily: theme.fonts.family.additional,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 400,
  color: theme.colors.neutral.N50,
});

export const StyledPricingCardPerTotalPrice = styled.div({
  fontFamily: theme.fonts.family.additional,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 400,
  color: theme.colors.neutral.N50,
});

export const StyledCta = styled(Cta)<{ width?: string }>(({ width }) => ({
  margin: 0,
  width: width ?? '100%',
  height: '32px',
  borderRadius: '6px',
  padding: 0,
  fontSize: theme.fonts.size.p_small,
  fontFamily: theme.fonts.family.main,
  fontWeight: 500,
  lineHeight: 0,
  [DOM_STATE.HOVER]: {
    padding: 0,
    transition: '0.4s',
    borderWidth: '1px',
    opacity: 0.8,
  },
  [MEDIA_QUERIES.TABLET]: {
    width: width ?? '100%',
    maxWidth: '100%',
  },
}));

export const StyledCtaExternal = styled.span({
  a: {
    margin: 0,
    width: '100%',
  },
});

export const Subtitle = styled.div({
  alignSelf: 'center',
  marginBottom: '24px',
  'p, em, strong': {
    fontFamily: theme.fonts.family.additional,
    fontSize: theme.fonts.size.p_medium,
    color: theme.colors.neutral.N80,
    textAlign: 'center',
    margin: 0,
  },

  width: '100%',
  justifyContent: 'center',
  display: 'flex',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: 'flex-start',
  },
});

export const SubtitleExtra = styled.div({
  marginBottom: '48px',
  display: 'flex',
  gap: '24px',
  width: '100%',
  justifyContent: 'center',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: 'flex-start',
  },
});

export const SubtitleExtraInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: theme.fonts.size.p_small,
  lineHeight: '24px',
});

export const PricingCardNeedMoreWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
  gap: '32px',
});

export const PricingCardNeedMore = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

// Table

export const StyledTable = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1024px',
  zIndex: 1,
  overflow: 'hidden',
  marginBottom: '56px',

  border: `1px solid ${theme.colors.neutral.N10}`,
  borderRadius: '12px',

  'div:last-child': {
    borderBottom: 'none',
  },
});

export const StyledTableHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.colors.neutral.N04,
  padding: '8px 24px',
});

export const StyledTableItemWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '16px 24px',
  borderBottom: `1px solid ${theme.colors.neutral.N10}`,
  gap: '8px',
});

export const StyledTableItem = styled.div<{
  align?: string;
  flexCol?: string;
  fontSize?: string;
}>(({ align, flexCol, fontSize }) => ({
  display: 'flex',
  justifyContent: align ?? 'initial',
  flex: flexCol ?? '2',
  gap: '8px',
  fontFamily: theme.fonts.family.additional,
  lineHeight: '24px',
  fontSize: fontSize ?? '16px',
}));

export const StyledTableItemTitle = styled.div({
  fontFamily: theme.fonts.family.additional,
  textTransform: 'capitalize',
});

export const StyledTableItemPrice = styled.div({
  fontFamily: theme.fonts.family.additional,
});

export const StyledTableItemNeedMoreTitle = styled.div({
  fontFamily: theme.fonts.family.additional,
  fontSize: theme.fonts.size.p_xlarge,
  lineHeight: '24px',
  fontWeight: 700,
});

export const StyledTableItemNeedMoreDescription = styled.div({
  fontFamily: theme.fonts.family.additional,
  fontSize: theme.fonts.size.p_small,
  lineHeight: '20px',
  fontWeight: 400,
});

// Features

export const FeaturesContainer = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    ...flexColContainerStyles,
    justifyContent: 'center',
    paddingBottom: itemsLength % 3 === 0 ? 0 : '36px',
    width: '100%',
    maxWidth: '1024px',
    borderRadius: '12px',
    padding: '24px',
    margin: '0 20px',
    backgroundColor: theme.colors.neutral.N04,
  })
);

export const FeaturesList = styled.div({
  // display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  rowGap: '16px',
  columnGap: '16px',
  flexWrap: 'wrap',

  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
});

export const Feature = styled.div({
  display: 'flex',
  color: theme.colors.black.S900,
  fontSize: theme.fonts.size.p_small,
  fontFamily: theme.fonts.family.additional,

  p: {
    fontFamily: theme.fonts.family.additional,
    marginLeft: theme.spacings.XS,
  },
  div: {
    width: '21px',
    height: '21px',
    img: {
      width: '21px',
      height: '21px',
    },
  },
});

export const FeatureTitle = styled.p({
  fontFamily: theme.fonts.family.additional,
  fontSize: theme.fonts.size.p_medium,
  color: theme.colors.black.S900,
  fontWeight: 500,
  marginBottom: theme.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    fontSize: theme.fonts.size.h4,
    lineHeight: theme.fonts.lineHeight.h4,
    fontWeight: 400,
    textAlign: 'left',
  },
});

export const PaymentMethodsSpacing = styled.div({
  marginTop: '64px',
});

// Payments methods

export const PaymentMethodsContainer = styled.div({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '1024px',
  gap: theme.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const PaymentMethodsListWrapper = styled.div({
  textAlign: 'left',
});

export const PaymentIcons = styled.div({
  opacity: 0.5,
  display: 'flex',
  gap: theme.spacings.S,
  marginBottom: '5px',
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: theme.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexWrap: 'wrap',
  },
});

export const PaymentMethodsDescription = styled.p({
  fontFamily: theme.fonts.family.main,
  color: theme.colors.gray.S500,
  fontWeight: 400,
  textAlign: 'right',

  marginBottom: 0,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    textAlign: 'left',
  },
});

export const PaymentMethodsText = styled.p({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  color: theme.colors.gray.S500,
  marginBottom: '5px',
  fontWeight: 400,
});
